import styled from 'styled-components';

const TestimonialSecWrapper = styled.section`
    padding: 80px 0;
    direction: ltr;
    @media (max-width: 575px) {
        padding: 60px 0;
    }
    @media (max-width: 575px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .glide {
        &:hover {
            .glide__prev--area,
            .glide__next--area {
                opacity: 1;
            }
        }
    }

    .glide_rtl {
        direction: rtl;
        text-align: right;
    }

    .glide__slides {
        padding-top: 10px;
        padding-bottom: 30px;

        .glide__slide {
            opacity: 0.5;
            pointer-events: none;
            transition: 0.25s ease;
            &.glide__slide--active {
                opacity: 1;
                pointer-events: auto;
                + .glide__slide {
                    @media (min-width: 800px) {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }
        }
    }

    .glide__controls {
        position: static;
        .glide__prev--area,
        .glide__next--area {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            transition: 0.15s ease-in-out;
            @media (max-width: 990px) {
                display: none;
            }

            button {
                font-size: 28px;
            }
        }
        .glide__prev--area {
            left: 10%;
            @media (max-width: 1400px) {
                left: 5%;
            }
        }
        .glide__next--area {
            right: 10%;
            @media (max-width: 1400px) {
                right: 5%;
            }
        }
    }

    @media (max-width: 990px) {
        .glide__slide--active .testimonial_item {
            box-shadow: 5px 0 20px rgba(0, 0, 0, 0.05);
        }
    }
`;

export const TestimonialItem = styled.div`
    padding: 30px;
    border-radius: 5px;
    background-color: #5c6ddc;
    transition: 0.425s ease;

    &:hover {
        box-shadow: 0 20px 40px -20px rgba(39, 79, 117, 0.25);
    }

    @media (max-width: 1300px) {
        padding: 30px;
    }
`;

export const ImageWrapper = styled.div`
    width: 45px;
    height: 45px;
    flex-basis: 45px;
    display: block;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 6px 30px 0 rgba(39, 79, 117, 0.2);
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
`;

export const CompanyImageWrapper = styled.div`
    width: 45px;
    height: 45px;
    margin-left: 12px;
    margin-right: 12px;
    overflow: hidden;
`;

export default TestimonialSecWrapper;
