import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../common/components/Image';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Container from '../../common/components/UI/Container';
import GlideCarousel from '../../common/components/GlideCarousel';
import GlideSlide from '../../common/components/GlideCarousel/glideSlide';
import { useIntl } from 'gatsby-plugin-intl';

import { __ } from '../../common/utils/LanguageHelper';
import TestimonialSecWrapper, {
    ImageWrapper,
    TestimonialItem,
    CompanyImageWrapper,
} from './testimonials.style';

const TestimonialSection = ({
    secTitleWrapper,
    secHeading,
    reviewStyle,
    TestimonialMeta,
    nameStyle,
}) => {
    const { locale } = useIntl();

    const TESTIMONIALS = [
        {
            review: 'موقع متميز وفريد من نوعه وخدمة ممتازة جدا',
            name: 'محمد علي',
            avatar:
                'https://arabsstock.com/uploads/preview/-1586971597l8mnu.jpg',
            companyLogo:
                'https://neyuon.com/wp-content/uploads/2019/09/lll.png',
        },
        {
            review: 'مصداقية في التعامل وخدمات رائعة',
            name: 'فايز الصفواني',
            avatar:
                'https://arabsstock.com/uploads/preview/-1586971597l8mnu.jpg',
            companyLogo:
                'https://www.jdfulwiler.com/wp-content/uploads/2017/08/abc-company-test-logo-300x205.png',
        },
        {
            review: 'سهولة التواصل مع الموظفين و إسناد المهام إليهم',
            name: 'محمود الجعفر',
            avatar:
                'https://arabsstock.com/uploads/preview/-1586971597l8mnu.jpg',
            companyLogo:
                'https://img.favpng.com/20/5/10/logo-product-sample-brand-magic-freebies-uk-business-png-favpng-8BuXPxXLUscBEBFUeP1tP3kL3.jpg',
        },
    ];

    //Carousel Options
    const carouselOptions = {
        type: 'carousel',
        autoplay: 4000,
        perView: 2,
        gap: 30,
        animationDuration: 800,
        peek: {
            before: 390,
            after: 390,
        },
        breakpoints: {
            1800: {
                perView: 2,
                peek: {
                    before: 220,
                    after: 220,
                },
            },
            1400: {
                perView: 2,
                peek: {
                    before: 160,
                    after: 160,
                },
            },
            1200: {
                perView: 2,
                peek: {
                    before: 100,
                    after: 100,
                },
            },
            990: {
                perView: 2,
                peek: {
                    before: 100,
                    after: 100,
                },
            },
            800: {
                perView: 1,
                peek: {
                    before: 120,
                    after: 120,
                },
            },
            575: {
                perView: 1,
                peek: {
                    before: 0,
                    after: 0,
                },
            },
        },
    };

    return (
        <TestimonialSecWrapper id="testimonial_section">
            <Container fullWidth>
                <Box {...secTitleWrapper}>
                    <Heading
                        {...secHeading}
                        content={__(
                            'ماذا يقول عملاؤنا عن أواصل',
                            'What’s clients say about Awasul'
                        )}
                    />
                </Box>
                <GlideCarousel
                    options={carouselOptions}
                    nextButton={
                        <Button
                            icon={<i className="flaticon-next" />}
                            variant="textButton"
                            aria-label="next button"
                        />
                    }
                    prevButton={
                        <Button
                            icon={<i className="flaticon-left-arrow" />}
                            variant="textButton"
                            aria-label="prev button"
                        />
                    }
                >
                    <>
                        {TESTIMONIALS.map((slideItem, index) => (
                            <GlideSlide
                                key={`testimonial-slide-${index}`}
                                className={`${locale === 'ar' && 'glide_rtl'}`}
                            >
                                <TestimonialItem className="testimonial_item">
                                    <Text
                                        content={slideItem.review}
                                        {...reviewStyle}
                                    />
                                    <Box {...TestimonialMeta}>
                                        <div
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            <ImageWrapper>
                                                <Image
                                                    src={slideItem.avatar}
                                                    alt={`reviewer-image-${index}`}
                                                />
                                            </ImageWrapper>
                                            <CompanyImageWrapper>
                                                <Image
                                                    src={slideItem.companyLogo}
                                                    alt={`company-logo-image-${index}`}
                                                />
                                            </CompanyImageWrapper>
                                        </div>
                                        <Box>
                                            <Heading
                                                content={slideItem.name}
                                                {...nameStyle}
                                            />
                                        </Box>
                                    </Box>
                                </TestimonialItem>
                            </GlideSlide>
                        ))}
                    </>
                </GlideCarousel>
            </Container>
        </TestimonialSecWrapper>
    );
};

TestimonialSection.propTypes = {
    secTitleWrapper: PropTypes.object,
    secHeading: PropTypes.object,
    secText: PropTypes.object,
    reviewStyle: PropTypes.object,
    TestimonialMeta: PropTypes.object,
    nameStyle: PropTypes.object,
    designationStyle: PropTypes.object,
    arrowStyle: PropTypes.object,
};

TestimonialSection.defaultProps = {
    secTitleWrapper: {
        mb: ['40px', '40px', '50px', '75px'],
    },
    secText: {
        as: 'span',
        display: 'block',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: '700',
        color: '#eb4d4b',
        mb: '10px',
    },
    secHeading: {
        textAlign: 'center',
        fontSize: ['20px', '30px'],
        fontWeight: '400',
        color: 'headingColor',
        letterSpacing: '-0.025em',
        mb: '0',
    },
    reviewStyle: {
        fontSize: ['16px', '16px', '17px', '17px', '19px'],
        fontWeight: '300',
        color: '#FFFFFF',
        lineHeight: '1.74',
        mb: ['30px', '30px', '30px', '40px', '55px'],
    },
    TestimonialMeta: {
        flexBox: true,
        flexDirection: 'column',
    },
    nameStyle: {
        as: 'h3',
        fontSize: '16px',
        fontWeight: '500',
        color: '#FFFFFF',
        letterSpacing: '-0.025em',
        mt: '11px',
        mb: 0,
    },
};

export default TestimonialSection;
