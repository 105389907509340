import React from 'react';
import { __ } from '../common/utils/LanguageHelper';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../common/theme/saas';
import Sticky from 'react-stickynode';
import { GlobalStyle, ContentWrapper } from '../containers/saas.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import Navbar from '../containers/Navbar';
import SEO from '../components/seo';
import { ResetCSS } from '../common/assets/css/style';
import Footer from '../containers/Footer';
import PartnerHistory from '../containers/PartnerHistory';
import TestimonialSection from '../containers/Testimonials';
import BackgroundShape from '../containers/BackgroundShape';

export default () => {
    return (
        <ThemeProvider theme={saasTheme}>
            <SEO title={__('العملاء', 'Clients')} />
            <ResetCSS />
            <GlobalStyle />
            <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                    <DrawerProvider>
                        <Navbar />
                    </DrawerProvider>
                </Sticky>
                <BackgroundShape
                    position="left"
                    className="background-shape__clients"
                />
                <PartnerHistory className="partner-history__clients" />
                <Footer />
            </ContentWrapper>
        </ThemeProvider>
    );
};
